<template>
    <div :class="'gallery-slider-module'">
        <div 
            :class="'gallery-slider-module-container'"
            @dblclick="setSelect()"
        >
            <i 
                class="fas fa-chevron-left"  
                @click="index--"
            />
            <div 
                class="gallery-slider-module-content" 
                v-if="config.images.length != 0"
            >
                <div  
                    :class="'gallery-slider-img-container ' + 'image-' + i" 
                    v-for="(image, i) in getCurrentImages" 
                    :key="'image-' + (i + index)"
                >
                    <a v-if="image.link && image.link != ''" :href="image.link">
                        <img :src="getImg(image.img)" class="slider-gallery-image" alt="missing img">
                    </a>
                    <img v-else :src="getImg(image.img)" alt="missing img" class="slider-gallery-image">
                </div>
            </div>
            <i 
                class="fas fa-chevron-right"  
                @click="index++"
            />
        </div>
        <div v-if="haveMarks" class="image-dots-container">
            <i v-for="j in config.images.length" :key="'image-dots' + j" class="fas fa-circle" :style="isFaded(j-1)"/>
        </div>
    </div>
</template>

<script>

    import * as imgManager from "@/store/imgManager";
    
    export default {
        props: ["config"],
        data(){
            return {
                haveMarks: true,
                index: 0
            }
        },
        computed:{
            getLimit(){
                return parseInt(this.config.limit);
            },
            getCurrentImages(){
                let current = []
                let images = this.config.images;
                for(let i=this.index; i < (this.getLimit + this.index); i++){
                    let tempI = i
                    if(i >= this.config.images.length){
                        tempI -= this.config.images.length;
                    }
                    let img = images[tempI];
                    let entry = {}
                    if(img.link){
                        entry = {img: img.img, link: img.link}
                    }else{
                        entry = {img: img.img}
                    }
                    current.push(entry)
                }
                return current
            }
        },
        watch: {
            index(){
                if(this.index >= this.config.images.length){
                    this.index = 0;
                }
                else if(this.index < 0){
                    this.index = this.config.images.length - 1;
                }
            }
        },
        methods:{
            setSelect(){
                this.$store.state.selectedSlider = this.index;
            },
            changeIndex(i){
                if(this.config.images.length > this.config.limit)
                {this.index += i;}
            },
            isFaded(i){
                for(let j=0; j < this.config.limit; j++){
                    if(i == ((this.index + j) % this.config.images.length))
                    {
                        return "opacity: 100%;";
                    }
                }
                return "opacity: 20%;"
            },
            getImg(image){
                let img = imgManager.getImage(image);
                if(img){
                    if(img.path){
                        return img.path;
                    }else{
                        return img.file;
                    }
                }
            }
        },
        mounted(){
            if(!this.config.images){
                this.config["images"] = [];
            }else{
                for(let i=0; i < this.config.images.length; i++){
                    if(typeof this.config.images[i] === "string"){
                        let temp = this.config.images[i];
                        this.config.images.splice(i, 1, {img: temp})
                    }
                }
            }
            if(!this.config.limit){
                this.config["limit"] = 0;
            }                  
        }
    }
      
</script>